<template>
  <div class="home">
    <h1 id="head-title">Ghana Missionaries</h1>
    <p>The Unofficial Website For Family Members and Others Interested In Ghana</p>
    <div class="ghana-image"></div>
  </div>
</template>

<script>


export default {
  name: 'Home',
  components: {
  }
}
</script>

<style scoped>
  

  .ghana-image {
  background-image: url("../assets/CoverPhotoStock.jpg"); /* The image used */
  background-color: #cccccc; /* Used if the image is unavailable */
  height: auto; /* You must set a specified height */
  min-height: 600px;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  }
  #home{
    height: 100%;
  }
</style>
